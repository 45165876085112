<template>
  <div class="business-reviewed-page">
    <div class="searchForm">
      <el-form
        ref="form"
        :model="form"
        :inline="true"
        label-position="left"
        class="form"
      >
        <el-form-item label="商户名称" label-width="70px">
          <el-input
            type="text"
            class="inputStyle"
            v-model="form.shopName"
            maxlength="20"
            clearable
            placeholder="请输入商户名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户账号" label-width="70px">
          <el-input
            type="text"
            class="inputStyle"
            v-model="form.shopAccount"
            maxlength="20"
            clearable
            placeholder="请输入商户账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号状态" label-width="90px">
          <el-select
            v-model="form.shopState"
            placeholder="请选择账号状态"
            clearable
          >
            <el-option
              v-for="item in shopStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属分公司" label-width="90px">
          <el-select
            v-model="form.spreadId"
            placeholder="请选择分公司"
            clearable
            filterable
            remote
            :loading="loading"
            :remote-method="searchBranchOffice"
            @focus="searchBranchOffice('')"
            @change="agentRemoteMethod('')"
          >
            <el-option
              v-for="item in branchOfficeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="开通方式" label-width="80px">
          <el-select v-model="form.isStaff" placeholder="请选择" clearable>
            <el-option v-for="item in isStaffList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="归属代理" label-width="80px">
          <el-select
            v-model="form.agentSid"
            clearable
            filterable
            remote
            reserve-keyword
            placeholder="请输入代理真实姓名"
            :remote-method="agentRemoteMethod"
            :loading="agentListLoading">
            <el-option
              v-for="item in agentOptions"
              :key="item.agentSid"
              :label="item.realName"
              :value="item.agentSid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <div class="timeChoose">开通时间</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <el-button type="primary" class="sure" @click="screen">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
        <div class="only-check-arrears-div flexbox flex-lr flex-align-center">
          <el-switch v-model="onlyCheckArrears" active-color="#EF3F46" inactive-color="#f5f5f5" @change="checkArrears"></el-switch>
          <div class="only-check-arrears-txt">只看欠费商户</div>
        </div>
      </div>
    </div>
    <div
      class="orderExplain flexbox flex-lr flex-align-center flex-justify-end"
    >
      <!-- <div class="orderExplainTxt">分润说明</div>
      <img class="question" src="/img/order/explain.png" alt="" @click="showTips"> -->
    </div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="shopId" label="序号" width="70"></el-table-column>
      <el-table-column
        prop="shopName"
        label="商户名字"
        min-width="140"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="shopAccount"
        label="商户账号"
        width="120"
      ></el-table-column>
      <el-table-column prop="createTime" label="开通时间" width="160">
        <template slot-scope="scope">
          <span>{{
            $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss')
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="area" label="区域"></el-table-column>
      <!-- <el-table-column prop="scanOrderNumber" label="堂食单量（笔）">
        <template slot-scope="scope">
         <span>{{scope.row.scanOrderNumber || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="wmOrderNumber" label="外卖单量（笔）">
        <template slot-scope="scope">
         <span>{{scope.row.wmOrderNumber || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="groupOrderNumber" label="团购单量（笔）">
        <template slot-scope="scope">
         <span>{{scope.row.groupOrderNumber || '--'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rebateOrderNumber" label="返利单量（笔）">
        <template slot-scope="scope">
         <span>{{scope.row.rebateOrderNumber || '--'}}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="totalProfit" label="总收益（元）">
        <template slot-scope="scope">
         <span>{{scope.row.totalProfit || '0'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="waitAccountAmount" label="待结算（元）"></el-table-column>
      <el-table-column prop="balance" label="可提现（元）"></el-table-column>
      <el-table-column prop="haveAccount" label="已提现（元）"></el-table-column>
      <el-table-column prop="shopState" label="账号状态" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.shopState == 1 ? '正常' : '预警' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="owner" label="归属代理"></el-table-column>
      <el-table-column prop="ownerTel" label="代理商账号"></el-table-column>
      <el-table-column prop="company" label="归属分公司" width="160" show-overflow-tooltip></el-table-column>
      <!-- <el-table-column prop="ownerTel" label="开通员工"></el-table-column>
      <el-table-column prop="ownerTel" label="开通员工电话"></el-table-column> -->
      <el-table-column prop="ownerTel" label="现有业务">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.supportBusiness" :key="index">
            <span class="mr10" v-if="item === 1">外卖</span>
            <span class="mr10" v-else-if="item === 2">团购</span>
            <span class="mr10" v-else-if="item === 4">堂食</span>
            <span class="mr10" v-else-if="item === 8">返利</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="补贴配送费" width="120">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="changeSubsidyDeliveryMoney(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="eatIn" label="堂食/外卖商品" width="120">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="toOrderAtStoreGoods(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="eatIn" label="团购商品" width="120">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="toBulkGoods(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="profit" label="操作" width="135">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="toEditMerchants(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showShelves(scope.row)"
            >上/下架</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="mt10"
      background
      :page-count="totalPage"
      :current-page.sync="currentPage"
      @current-change="pageChange"
    >
    </el-pagination>
    <!-- <Dialog ref="business_1"></Dialog> -->

    <!-- 绑定顺丰门店弹窗 -->
    <el-dialog
      title="顺丰配送设置"
      :visible.sync="shunfengDialog"
      width="500px"
      :close-on-click-modal="false"
      :show-close="false"
      >
      <div>
        <span>松鼠商家名称：{{shopName}}</span>
      </div>
      <div class="mt20">
        <span>关联顺丰门店：</span>
        <el-input v-model="sfShopNo" placeholder="请输入顺丰门店ID" style="width:200px;"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shunfengDialog = false">取 消</el-button>
        <el-button type="primary" @click="bindSfShop">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商家详情弹窗 -->
    <el-dialog
      title="商家详情"
      :visible.sync="businessDetailDialog"
      :show-close="false"
      center
      width="450px"
    >
      <div class="detailItemBox">
        <div class="detailItem">
          <span>商户名字：</span>
          <span>{{ detailData.shopName }}</span>
        </div>
        <div class="detailItem">
          <span>商户登录手机号：</span>
          <span>{{ detailData.shopPhone }}</span>
        </div>
        <div class="detailItem">
          <span>开通时间：</span>
          <span>{{
            $formatDate(detailData.createTime, 'yyyy-MM-dd HH:mm:ss')
          }}</span>
        </div>
        <div class="detailItem">
          <span>归属员工：</span>
          <span>{{ detailData.staffName }}</span>
        </div>
        <div class="detailItem">
          <span>员工手机号：</span>
          <span>{{ detailData.staffPhone }}</span>
        </div>
        <div class="detailItem">
          <span>开通情况：</span>
          <span>{{ detailData.isStaff == 1 ? '员工开通' : '后台开通' }}</span>
        </div>
        <div class="detailItem">
          <span>归属分公司：</span>
          <span>{{ detailData.spreadName }}</span>
        </div>
        <div class="detailItem">
          <span>外卖开通状态：</span>
          <span>{{ binaryAndCheck(detailData.supportBusiness, 1) ? '是' : '否' }}</span>
        </div>
        <div class="detailItem">
          <span>堂食开通状态：</span>
          <span>{{ binaryAndCheck(detailData.supportBusiness, 4) ? '是' : '否' }}</span>
        </div>
        <div class="detailItem">
          <span>团购开通状态：</span>
          <span>{{ binaryAndCheck(detailData.supportBusiness, 2) ? '是' : '否' }}</span>
        </div>
        <div class="detailItem">
          <span>返利开通状态：</span>
          <span>{{ binaryAndCheck(detailData.supportBusiness, 8) ? '是' : '否' }}</span>
        </div>
        <div class="detailItem">
          <span>本月外卖订单数量：</span>
          <span>{{ orderStaticsData.takeAwayCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>本月堂食订单数量：</span>
          <span>{{ orderStaticsData.eatInCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>商品数量：</span>
          <span>{{ goodsStaticsData.goodsAllCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>售卖中数量：</span>
          <span>{{ goodsStaticsData.goodsNormalCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>下架数量：</span>
          <span>{{ goodsStaticsData.goodsDownCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>台位数量：</span>
          <span>{{ tableStaticsData.tableCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>已绑定二维码台位数量：</span>
          <span>{{ tableStaticsData.tableBoundCount || 0 }}</span>
        </div>
        <div class="detailItem">
          <span>详细地址：</span>
          <span>{{ detailData.shopAddress || '' }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="businessDetailDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 二维码设置弹窗 -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      width="500px"
      class="dialong-style"
      center
    >
      <p>
        <span class="remark-line"></span>
        <span class="remark-title">引流二维码设置</span>
      </p>
      <header style="padding: 30px 0">
        <el-checkbox v-model="codeform.status">是否开启</el-checkbox>
      </header>
      <aside v-if="codeform.status">
        <p style="padding-bottom: 5px">二维码设置</p>
        <div class="iblock">
          <img
            class="pointer team_img"
            title="点击下方按钮添加图片"
            id="teamImage"
            width="150"
            height="150"
            :src="codeform.secondQrUrl ? codeform.secondQrUrl : defaultImg"
          />
          <el-upload
            class=" "
            action="#"
            :show-file-list="false"
            :http-request="img_head_upload"
          >
            <p class="unploadButton">选择文件</p>
          </el-upload>
        </div>
        <p style="line-height: 40px">链接广告文字内容</p>
        <el-input v-model="codeform.linkBillboardContent" clearable> </el-input>
        <!-- <p style="line-height: 40px">链接url</p>
        <el-input :disabled="isread" v-model="codeform.linkUrl" clearable>
        </el-input> -->
      </aside>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveBillboard">保存</el-button>
        <el-button @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

     <!-- 选择上下架业务 -->
    <el-dialog
      title="业务上架"
      :visible.sync="businessShelveDialog"
      width="500px"
      :close-on-click-modal="false"
      :show-close="false"
      >
      <div>
        <span>选择上架的业务</span>
      </div>
      <div class="mt20">
        <el-checkbox-group v-model="businessCheckList">
          <el-checkbox v-for="(item,i) in businessList" :key="i" :label="item.label"></el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="businessShelveDialog = false">取 消</el-button>
        <el-button type="primary" @click="shelveBusiness()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Dialog from '@/components/common/dialog.vue';
import { UploadImg } from '@/global/common';
import { $agent_selectAgent } from '@/api/agentAll';
import {
  $sp_shopInfoList,
  $sp_shopInfoDetail,
  $sg_orderStatics,
  $sg_goodsStatics,
  $sg_tableStatics,
  // eslint-disable-next-line no-unused-vars
  $sg_saveBillboard,
  $sg_getBillboard,
  $sp_bindSfShop,
  $sp_updateShopSupportBusiness,
  $sp_shopInfo,
  $sp_updateSubsidyDeliveryMoney,
} from '@/api/business';
import { $sp_spreadList } from '@/api/order';
import defaultImg from '@/assets/img/unploadHeadImg.png';

export default {
  name: 'reviewed',
  // components: {
  //   Dialog,
  // },
  data() {
    return {
      defaultImg,
      isread: false,
      codeform: {
        id: null,
        shopId: null,
        status: false,
        secondQrUrl: '',
        linkBillboardContent: '加粉丝福利群，更多福利，更多优惠',

      },
      centerDialogVisible: false,
      form: {
        shopName: '',
        shopPhone: '',
        staffPhone: '',
        spreadId: '',
        businessType: '',
        // isStaff: '',
        startTime: '',
        endTime: '',
        agentSid: '',
      },
      branchOfficeList: [],
      businessCheckList: [],
      businessList: [],
      isStaffList: [
        { value: 0, label: '后台开通' },
        { value: 1, label: '员工开通' },
      ],
      shopStateList: [
        { value: 0, label: '预警' },
        { value: 1, label: '正常' },
      ],
      businessTypeList: [
        { value: 1, label: '外卖' },
        { value: 4, label: '到店点餐' },
        { value: 5, label: '外卖+到店点餐' },
      ],
      time: '',
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      loading: false,
      businessShelveDialog: false,
      businessDetailDialog: false, // 是否显示商户详情弹窗
      detailData: {}, // 商户详情
      orderStaticsData: '', // 商户详情-订单数量统计
      goodsStaticsData: '', // 商户详情-商品数量统计
      tableStaticsData: '', // 商户详情-台位数量统计
      shunfengDialog: false,
      sfShopNo: '', // 顺丰门店id
      shopName: '',
      currentRowData: '',
      onlyCheckArrears: false,
      agentOptions: [],
      agentListLoading: false,
    };
  },
  created() {
    this.supportBusinessConfig = new this.$BinaryConfig('supportBusiness');
    const cacheSearchForm = JSON.parse(sessionStorage.getItem('shop_list_search'));
    if (cacheSearchForm) {
      this.currentPage = +cacheSearchForm.currentPage;
      this.form = cacheSearchForm.form;
      this.time = cacheSearchForm.time;
      this.onlyCheckArrears = cacheSearchForm.onlyCheckArrears;
      this.branchOfficeList = cacheSearchForm.branchOfficeList;
      sessionStorage.removeItem('shop_list_search');
    }
    this.shopInfoList();
    // this.supportBusinessConfig = new this.$BinaryConfig('supportBusiness');
    // console.log(this.supportBusinessConfig,
    //   this.supportBusinessConfig.getSelectFinalValue([2, 8]),
    //   this.supportBusinessConfig.getValue(10), 'this.supportBusinessConfig');
  },
  methods: {

    agentRemoteMethod(query) {
      this.agentListLoading = true;
      $agent_selectAgent({
        realName: query,
        spreadId: this.form.spreadId,
        pageCurrent: 1,
        pageSize: 40,
      }).then((resp) => {
        this.agentListLoading = false;
        this.agentOptions = resp.records;
      });
    },

    // 保存设置
    saveBillboard() {
      const params = this.codeform;

      if (this.codeform.status) {
        if (!this.codeform.secondQrUrl) {
          this.$message.error('请上传图片');
          return;
        }
        if (!this.codeform.linkBillboardContent) {
          this.$message.error('请输入链接广告');
          return;
        }
        // if (!this.codeform.linkUrl) {
        //   this.$message.error('请输入链接url');
        //   return;
        // }
      }
      params.status = +this.codeform.status;
      $sg_saveBillboard(params).then((res) => {
        this.centerDialogVisible = false;
        this.shopInfoList();
        console.log(res, 'ee');
      });
    },
    // 打开二维码修改配置
    openCodeDialog(id, status) {
      // const state = true;
      console.log(status);
      if (status) {
        $sg_getBillboard({ shopId: id }).then((res) => {
          this.isread = true;
          this.codeform = res;
          // if (!this.codeform.linkUrl) {
          //   this.codeform.linkUrl = `${process.env.VUE_APP_WAP_DOMAIN}/sign/${res.shopToken}/foods/userCenterBind`;
          // }
          this.codeform.status = !!res.status;
          this.centerDialogVisible = true;
        });
      } else {
        this.codeform = {
          id: null,
          shopId: id,
          status: false,
          secondQrUrl: '',
          linkBillboardContent: '加粉丝福利群，更多福利，更多优惠',

        };
        // $sg_getBillboard({ shopId: id }).then((res) => {
        //   this.codeform = {
        //     id: null,
        //     shopId: id,
        //     status: false,
        //     secondQrUrl: '',
        //     linkBillboardContent: '加粉丝福利群，更多福利，更多优惠',
        //     linkUrl: `${process.env.VUE_APP_WAP_DOMAIN}/sign/${res.shopToken}/foods/userCenterBind`,
        //   };
        // });

        this.isread = false;
        this.centerDialogVisible = true;
      }
    },
    // 图片上传
    async img_head_upload(file) {
      // if (!this.headFlag) {
      //   this.$message('您没有修改店铺头像的权限');
      //   return;
      // }
      const uploadImg = new UploadImg({ file: file.file, type: 'logo' });
      const resp = await uploadImg.upload();
      this.codeform.secondQrUrl = resp;
    },
    // 筛选
    screen() {
      this.currentPage = 1;
      this.shopInfoList();
    },
    // 清空筛选条件
    clear() {
      this.form = {
        shopName: '',
        shopPhone: '',
        staffPhone: '',
        spreadId: '',
        businessType: '',
        // isStaff: '',
        startTime: '',
        endTime: '',
      };
      this.time = '';
      this.shopInfoList();
    },
    // 校验手机号-最多输入11位
    inputPhone(value) {
      if (value.length > 11) {
        this.form.phone = value.slice(0, 11);
      }
    },
    // 请求商家列表数据
    shopInfoList() {
      const params = {
        current: this.currentPage,
        pageSize: 20,
        arrearsStatus: this.onlyCheckArrears ? 1 : '',
        ...this.form,
      };
      params.startTime = this.time && this.time.length > 0
        ? this.$formatDateByDate(this.time[0], 'yyyy-MM-dd')
        : '';
      params.endTime = this.time && this.time.length > 0
        ? this.$formatDateByDate(this.time[1], 'yyyy-MM-dd')
        : '';
      $sp_shopInfoList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
        this.currentPage = res.current;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
      };
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 显示商家详情弹窗
    showDetail(id) {
      this.shopInfoDetail(id);
      this.orderStatics(id);
      this.goodsStatics(id);
      this.tableStatics(id);
      this.businessDetailDialog = true;
    },
    // 显示业务上下架弹窗
    showShelves(data) {
      const row = data;
      this.currentRowData = row;
      this.businessCheckList = [];
      this.businessList = [];
      // this.businessList.push(supportBusinessList.find((i) => item === i.label));
      const supportBusinessList = new this.$BinaryConfig('supportBusiness');
      const businessList = supportBusinessList.getValue(data.oldBusiness);
      businessList.forEach((item) => {
        if (item.open) {
          this.businessList.push(item);
        }
      });
      console.log(supportBusinessList.getValue(data.oldBusiness));
      if (data.oldBusiness) {
        row.supportBusiness.forEach((item) => {
          this.businessCheckList.push(supportBusinessList.value.find((i) => item === i.value).label);
        });
        this.businessShelveDialog = true;
      } else {
        // this.businessCheckList = [];
        this.$message.error('此商户暂未开通业务，请设置开通业务。');
      }
    },
    toOrderAtStoreGoods(row) {
      this.cacheSearchForm();
      window.sessionStorage.setItem('shopId', row.shopId);
      this.$router.push({
        name: 'goodsList',
        query: {
          isEditable: 0,
          id: row.shopId,
        },
      });
    },
    toBulkGoods(data) {
      this.cacheSearchForm();
      this.$router.push({
        name: 'groupManagementlist',
        query: {
          type: 1,
          shopId: data.shopId,
        },
      });
    },
    toEditMerchants(data) {
      this.cacheSearchForm();
      this.$router.push({
        name: 'editMerchants',
        query: {
          shopId: data.shopId,
          type: '2',
        },
      });
    },
    // 缓存搜索条件
    cacheSearchForm() {
      const params = {
        form: this.form,
        time: this.time,
        onlyCheckArrears: this.onlyCheckArrears,
        currentPage: this.currentPage,
        branchOfficeList: this.branchOfficeList,
      };
      sessionStorage.setItem('shop_list_search', JSON.stringify(params));
    },
    // 打开商户绑定顺丰门店弹窗
    bindShunFeng(shopId, shopName, sfShopNo) {
      this.sfShopNo = sfShopNo || '';
      this.shopId = shopId;
      this.shopName = shopName;
      this.shunfengDialog = true;
    },
    async shelveBusiness() {
      let supportBusiness = [];
      if (this.businessCheckList.length) {
        this.businessCheckList.forEach((item) => {
          supportBusiness.push(this.businessList.find((i) => item === i.label).value);
        });
        supportBusiness = supportBusiness.join(',');
      } else {
        supportBusiness = '';
      }
      const params = {
        supportBusiness,
        shopId: this.currentRowData.shopId,
      };
      await $sp_updateShopSupportBusiness(params).then((res) => {
        console.log(res);
      });
      this.businessShelveDialog = false;
      this.shopInfoList();
    },
    // 绑定顺丰门店
    bindSfShop() {
      if (!this.sfShopNo) {
        this.$message.error('顺丰门店ID不能为空');
        return;
      }
      // 校验规则：13位纯数字
      if (!/^[0-9]+$/.test(this.sfShopNo) || this.sfShopNo.length !== 13) {
        this.$message.error('输入不符合规则');
        return;
      }
      const params = {
        sfShopNo: this.sfShopNo,
      };
      $sp_bindSfShop(this.shopId, params).then(() => {
        this.shunfengDialog = false;
        this.shopId = '';
        this.shopName = '';
        this.shopInfoList();
      });
    },
    // 修改补贴配送费
    async changeSubsidyDeliveryMoney(row) {
      const res = await $sp_shopInfo(row.shopId);
      const systemSubsidy = res.basicDetailVo.systemSubsidy;
      this.$prompt('请输入补贴配送费', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputType: 'number',
        inputValue: systemSubsidy,
        closeOnClickModal: false,
        inputValidator(value) {
          if (Number(value) !== Number(value).toFixed(2) * 1) {
            return '配送费最多保留两位小数';
          }
          if (Number(value) < 0 || Number(value) > 5) {
            return '配送费应在0-5之间';
          }
          return true;
        },
      })
        .then(({ value }) => {
          this.$confirm(`确定要将补贴配送费由${systemSubsidy}改成${value}吗?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true,
          }).then(() => {
            const params = {
              shopId: row.shopId,
              systemSubsidy: value,
            };
            $sp_updateSubsidyDeliveryMoney(params);
          }).catch(() => {});
        })
        .catch(() => {});
    },
    // 商家详情
    shopInfoDetail(id) {
      const params = {
        shopId: id,
      };
      $sp_shopInfoDetail(params).then((res) => {
        this.detailData = res;
      });
    },
    // 商家详情-商户订单数量统计
    orderStatics(id) {
      $sg_orderStatics(id).then((res) => {
        this.orderStaticsData = res;
      });
    },
    // 商家详情-商户商品数量统计
    goodsStatics(id) {
      $sg_goodsStatics(id).then((res) => {
        this.goodsStaticsData = res;
      });
    },
    // 商家详情-商户台位数量统计
    tableStatics(id) {
      $sg_tableStatics(id).then((res) => {
        this.tableStaticsData = res;
      });
    },
    // 翻页
    pageChange() {
      this.shopInfoList();
    },
    // 显示分润说明弹窗
    // showTips() {
    //   this.$refs.business_1.dialogs = {
    //     icon: '/img/dialog/icon-order.png',
    //     title2: '分润说明',
    //     content2: [
    //       {
    //         title: '本月参与抽佣金额',
    //         list: ['(每笔订单金额x抽佣比例-支付手续费)x代理商分润比例=您的每笔订单收益'],
    //       },
    //       {
    //         title: '名称定义说明',
    //         list: ['商户号：商户系统唯一编号，用于商户查询商户筛选等',
    //           '商户名：商户名为商户点名（大部分为缩写）',
    //           '商户联系电话：商户预留手机号',
    //           '分润时间：订单已完成后的2分钟内',
    //           '订单金额：用户下单时，所有收费总和',
    //           '实际支付金额：用户抵扣掉优惠券、积分、折扣等实际用户支付金额',
    //           '配送费用：第三方或商户配送商品费用',
    //           '抽佣比例：每笔订单平台抽取实际支付金额的比例',
    //           '平台扣除总金额：实际代理商可参与分润的总金额',
    //           '我的分润：此笔订单我的分润收益',
    //           '状态：分润状态'],
    //       },
    //     ],
    //     done: '已知晓',
    //   };
    //   this.$refs.business_1.showDialog = true;
    // },
    checkArrears() {
      this.currentPage = 1;
      this.shopInfoList();
    },
  },
};
</script>
<style scoped lang="scss">
.dialong-style {
  /deep/
  .el-dialog__body {
    padding: 25px 50px 30px !important;
  }
  .remark-line {
    display: inline-block;
    height: 20px;
    width: 4px;
    border-radius: 4px;
    background: red;
  }
  .remark-title {
    display: inline-block;
    vertical-align: 3px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 5px;
  }
  .unploadButton {
    width: 150px;
    height: 32px;
    border: 1px solid #ef3f46;
    border-radius: 4px;
    height: 32px;
    background: #ef3f46;
    border-radius: 4px;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
  }
}
.business-reviewed-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.el-form--inline .el-form-item {
  margin-right: 50px;
}
.inputStyle {
  width: 180px;
}
.timeChoose {
  width: 110px;
  text-align: left;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.only-check-arrears-div{
  margin: 0 0 0 30px;
}
.only-check-arrears-txt{
  font-size: 14px;
  color: #606266;
  margin: 0 0 0 15px;
}
.sure {
  margin-left: 30px;
}
.orderExplain {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.orderExplainTxt {
  font-size: 14px;
  font-weight: 400;
  color: #f22424;
}
.el-table {
  min-height: 580px;
  & > th {
    background-color: #ef3f46;
    color: #fff;
  }
}
.question {
  width: 14px;
  height: 14px;
  margin-left: 8px;
  cursor: pointer;
}
.status1 {
  color: #333;
}
.status2 {
  color: #f22424;
}
// 详情弹窗
.detailItemBox {
  width: 100%;
  .detailItem {
    margin-top: 20px;
    span {
      margin-right: 5px;
    }
  }
}

</style>
