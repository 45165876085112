import { Message } from 'element-ui';
import { $$p_uploadForKindeditor, $sp_createPlatPicture } from '@/api/common';
import { verifyTelByMessage, copyStringCom } from '@/utils/utils';
import UploadImgCom from '@/utils/file/upload';

export const clearpop = Message;

export const verifyTel = (tel) => verifyTelByMessage(tel, Message.warning);

export class UploadImg extends UploadImgCom {
  constructor(option) {
    const secondOption = {
      uploadApiFn: $$p_uploadForKindeditor,
      createPlatPicture: $sp_createPlatPicture,
    };
    super(option, secondOption);
  }
}

export const copyString = (string) => copyStringCom(string, Message.success, Message.error);
